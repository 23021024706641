<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Examine the IR spectrum provided for compound D.</p>

      <p class="mb-2">
        Consider the peaks at
        <chemical-latex content="3000" />
        and
        <chemical-latex content="1750 \text{cm}^{-1}" />
        and check the types of bonds they represent in the molecule.
      </p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LBA2_M4Q1d',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: '$\\ce{O-H or N-H}$', value: 'OHorNH'},
        {text: '$\\ce{C-H} (sp^3)$', value: 'CHsp3'},
        {text: '$\\ce{C-H} (sp^2)$', value: 'CHsp2'},
        {text: '$\\ce{C-H} (sp)$', value: 'CHsp'},
        {text: '$\\ce{C=O}$', value: 'CO'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
